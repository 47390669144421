import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
} from '@mui/material';

import React from 'react';

const ConfirmDeleteModal = ({ open, name, onClose, onDelete, onCancel, actionName = 'delete' }) => <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
>
    <DialogTitle id="alert-dialog-title">
        {`Are you sure you want to ${actionName} "${name}"?`}
    </DialogTitle>
    <DialogActions>
        <Button onClick={onDelete} color="warning">
            {actionName}
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
    </DialogActions>
</Dialog>

export default ConfirmDeleteModal;