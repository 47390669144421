import React, { useState } from 'react';

import ConfirmDeleteModal from '@components/ConfirmDeleteModal';
import { DELETE_COLLECTION } from '@mutations/collections';
import { DELETE_LAYER } from '@mutations/layers';
import { DELETE_SUBCOLLECTION } from '@mutations/subcollections';
import { DELETE_VARIATION } from '@mutations/variations';
import { useMutation } from '@apollo/client';

const withResourceDelete = (Component) => (props) => {
  const { resource, onClick, ...rest } = props;

  const getResourceId = (resource) => `${resource.__typename}:${resource.id}`

  const [deleteCollection] = useMutation(DELETE_COLLECTION);
  const deleteCollectionParams = (collection) => ({
    variables: { collectionId: collection.id },
    update: (cache, { data: { deleteCollection } }) => {
      cache.evict({ id: cache.identify({ id: collection.id, __typename: 'Collection' }) });
      cache.gc();
    },
    optimisticResponse: {
      deleteCollection: {
        __typename: "Collection",
        id: collection.id
      }
    }
  })

  const [deleteSubcollection] = useMutation(DELETE_SUBCOLLECTION);
  const deleteSubcollectionParams = (subcollection) => ({
    variables: { subcollectionId: subcollection.id },
    update: (cache, { data: { deleteSubcollection } }) => {
      cache.modify({
        id: cache.identify({ id: subcollection.collectionId, __typename: 'Collection' }),
        fields: {
          subcollections: (resources) => resources.filter(resource => resource.__ref !== getResourceId(deleteSubcollection))
        }
      });
    },
    optimisticResponse: {
      deleteSubcollection: {
        __typename: "Subcollection",
        id: subcollection.id
      }
    }
  })

  const [deleteLayer] = useMutation(DELETE_LAYER);
  const deleteLayerParams = (layer) => ({
    variables: { layerId: layer.id },
    update: (cache, { data: { deleteLayer } }) => {
      const variations = layer.variations.flatMap(variation => variation.components);
      const variationComponentLayers = variations.map(({ layer }) => layer)
      variationComponentLayers.forEach(({ id, __typename }) => {
        cache.evict({ id: cache.identify({ id, __typename }) });
      })
      cache.modify({
        id: cache.identify({ id: layer.subcollectionId, __typename: 'Subcollection' }),
        fields: {
          layers: (resources) => {
            const resourceIds = [
              getResourceId(deleteLayer),
              ...variationComponentLayers.map(layer => getResourceId(layer))
            ]

            return resources.filter(resource => !resourceIds.includes(resource.__ref))
          }
        }
      });
      cache.gc();
    },
    optimisticResponse: {
      deleteLayer: {
        __typename: "Layer",
        id: layer.id
      }
    }
  })

  const [deleteVariation] = useMutation(DELETE_VARIATION);
  const deleteVariationParams = (variation) => ({
    variables: { variationId: variation.id },
    update: (cache, { data: { deleteVariation } }) => {
      variation.components.forEach(({ id, layer }) => {
        cache.evict({ id: cache.identify({ id, __typename: 'Variation' }) });
        cache.evict({ id: cache.identify({ id: layer.id, __typename: 'Layer' }) });
      })
      cache.evict({ id: cache.identify({ id: deleteVariation.id, __typename: 'Variation' }) });
      cache.gc();
    },
    optimisticResponse: {
      deleteVariation: {
        __typename: 'Variation',
        id: variation.id
      }
    }
  })

  const deleteResource = (resource) => {
    switch (resource.__typename) {
      case 'Collection':
        deleteCollection(deleteCollectionParams(resource));
        break
      case 'Subcollection':
        deleteSubcollection(deleteSubcollectionParams(resource));
        break;
      case 'Layer':
        deleteLayer(deleteLayerParams(resource))
        break;
      case 'Variation':
        deleteVariation(deleteVariationParams(resource))
        break;
      default:
        throw new Error(`Updating state not supported for type ${resource.__typename}`)
    }
  }

  const [open, setOpen] = useState(false);

  const onHandleClick = (event) => {
    setOpen(true);
    event.stopPropagation();
  };

  const onHandleDelete = (event) => {
    deleteResource(resource)
    setOpen(false);
    event.stopPropagation();
    event.preventDefault();
  }

  const onHandleCancel = (event) => {
    setOpen(false);
    event.stopPropagation();
  };

  return <React.Fragment>
    <Component onClick={onHandleClick} {...rest} />
    <ConfirmDeleteModal
      open={open}
      name={resource.name}
      onDelete={onHandleDelete}
      onClose={onHandleCancel}
      onCancel={onHandleCancel} />
  </React.Fragment>

}

export default withResourceDelete;