import { Card, CardActionArea, CardContent, Typography } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { CREATE_COLLECTION } from '@mutations/collections';
import React from 'react';
import { navigate } from 'gatsby';
import { useMutation } from '@apollo/client';

const AddCollectionButton = (props) => {
  const [createCollection] = useMutation(
    CREATE_COLLECTION,
    {
      onCompleted({ createCollection }) {
        if (createCollection) {
          navigate(`/collections/${createCollection.id}`);
        }
      },
    }
  );

  return (
    <Card sx={{ display: 'inline-flex' }}>
      <CardActionArea sx={{ display: 'flex' }} onClick={createCollection}>
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            New Collection
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Design a new NFT collection
          </Typography>
        </CardContent>
        <CardContent>
          <AddIcon />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default AddCollectionButton;
