import { COLLECTION_FIELDS } from '@queries/collections';
import { gql } from '@apollo/client';

const COLLECTION_CREATED = gql`
  subscription OnCollectionCreated($userId: ID!) {
    collectionCreated(userId: $userId) {
      ...CollectionFields
    }
  }
  ${COLLECTION_FIELDS}
`;

export { COLLECTION_CREATED }