import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Paper,
  Skeleton,
  Typography
} from '@mui/material';

import CloseButton from '@components/Buttons';
import React from 'react';
import { navigate } from 'gatsby';
import withResourceDelete from '@components/withResourceDelete';

const DeleteResourceCloseButton = withResourceDelete(CloseButton);

const CollectionLayout = ({ title, header, onClick }) => {
  const isClickable = typeof onClick === 'function';

  const content = <Paper elevation={2}>
    <CardContent sx={{ p: 0, m: 0 }}>
      {header}
    </CardContent>
    <CardContent sx={{ m: 0, p: 4, pt: 0 }}>
      <Typography component="span" variant="h6">
        {title}
      </Typography>
    </CardContent>
  </Paper>

  return <Card
    variant="outlined"
    sx={{ width: 250, mr: 2, mb: 2 }}
    {...(isClickable ? { onClick } : {})}
  >
    {isClickable ?
      <CardActionArea component="a" sx={{ height: '100%' }}>
        {content}
      </CardActionArea>
      :
      content}
  </Card>
}

const CollectionSkeleton = () =>
  <CollectionLayout
    header={
      <Box sx={{ p: 1 }}>
        <Skeleton variant="circular" width={20} height={20} sx={{ ml: 'auto' }} />
      </Box>}
    title={<Skeleton variant="text" sx={{ mb: 0.95 }} />}
  />

const Collection = ({ collection, onDelete }) =>
  <CollectionLayout
    header={!collection.isSharedCollection
      ? <Box sx={{ textAlign: 'right' }}>
        <DeleteResourceCloseButton
          resource={collection}
          sx={{ m: 0.5, mr: 0.5, p: 0.5 }}
        />
      </Box>
      : <Box sx={{ p: 2 }}></Box>
    }
    title={collection.name}
    onClick={() => navigate(`/collections/${collection.id}`)}
  />

Collection.Skeleton = CollectionSkeleton

export default Collection;
