import GridViewIcon from '@mui/icons-material/GridView';
import React from 'react';
import SquareIconButton from '@components/SquareIconButton';

const HomeButton = ({ onClick, sx = {} }) => <SquareIconButton
    onClick={onClick}
    sx={{ p: 0.5, m: 1, mr: 1.5, ...sx }}
>
    <GridViewIcon sx={{ fontSize: '1.2rem', p: 0 }} />
</SquareIconButton>

export default HomeButton;