import { SUBCOLLECTION_FIELDS } from '@queries/subcollections';
import { gql } from '@apollo/client';

const CREATE_SUBCOLLECTION = gql`
  mutation CreateSubcollection($collectionId: ID!) {
    createSubcollection(collectionId: $collectionId) {
      ...SubcollectionFields
    }
  }
  ${SUBCOLLECTION_FIELDS}
`;

const UPDATE_SUBCOLLECTION_STATE = gql`
  mutation UpdateSubcollection(
    $subcollectionId: ID!, 
    $state: SubcollectionStateInput!
  ) {
    updateSubcollection(id: $subcollectionId, state: $state) {
      ...SubcollectionFields
    }
  }
  ${SUBCOLLECTION_FIELDS}
`;

const UPDATE_SUBCOLLECTION_NAME = gql`
  mutation UpdateSubcollection(
    $subcollectionId: ID!, 
    $name: String!, 
  ) {
    updateSubcollection(id: $subcollectionId, name: $name) {
      ...SubcollectionFields
    }
  }
  ${SUBCOLLECTION_FIELDS}
`;

const DELETE_SUBCOLLECTION = gql`
  mutation DeleteSubcollection($subcollectionId: ID!) {
    deleteSubcollection(id: $subcollectionId) {
      id
    }
  }
`;

export {
  CREATE_SUBCOLLECTION,
  UPDATE_SUBCOLLECTION_STATE,
  UPDATE_SUBCOLLECTION_NAME,
  DELETE_SUBCOLLECTION
};
