import { Box, IconButton, Tooltip } from '@mui/material';

import React from "react";

const SquareIconButton = ({ tooltipTitle, selected, onClick, sx, children, ...rest }) => {
  const selectedStyle = {
    backgroundColor: '#545454',
  }
  const style = {
    borderRadius: 1,
    ...selected ? selectedStyle : {},
    ...sx
  }
  // This prevents parents from triggering ripple effects
  const mouseDown = (event) => {
    event.stopPropagation();
  }

  const content = <Box sx={{ p: 0, m: 0, display: 'inline-block' }} {...rest}>
    <IconButton sx={style} onClick={onClick} onMouseDown={mouseDown}>
      {children}
    </IconButton>
  </Box>

  return (
    tooltipTitle ?
      <Tooltip
        title={tooltipTitle}
        enterDelay={500}
        leaveDelay={200}>
        {content}
      </Tooltip >
      :
      content
  )
}
export default SquareIconButton;

SquareIconButton.defaultProps = {
  tooltipTitle: undefined,
  onClick: () => { },
  selected: false,
  sx: {},
}
