import { Box, Paper, Typography } from '@mui/material';

import AddCollectionButton from '@components/AddCollectionButton';
import { COLLECTIONS_LIGHT } from '@queries/collections';
import { COLLECTION_CREATED } from '@subscriptions/collections';
import Collection from '@components/Collection';
import React from 'react';
import { uniqueItems } from '@lib/helper';
import { useQuery } from '@apollo/client';

const Collections = ({ currentUser }) => {
  const { loading, error, data, subscribeToMore } = useQuery(COLLECTIONS_LIGHT, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
  });

  if (currentUser) {
    subscribeToMore({
      document: COLLECTION_CREATED,
      variables: { userId: currentUser.id },
      updateQuery: (previousData, { subscriptionData }) => {
        if (!subscriptionData.data) return previousData;
        const newItem = subscriptionData.data.collectionCreated;
        return Object.assign({}, previousData, {
          collections: uniqueItems([newItem, ...previousData.collections], 'id'),
        });
      },
      onError: (err) => console.error('err', err),
    });
  }

  if (error) return <p>Error :(</p>;

  const sharedCollections = data?.collections.filter(({ isSharedCollection }) => isSharedCollection);

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <AddCollectionButton />
      </Box>
      {sharedCollections?.length > 0 &&
        <Box sx={{ mb: 3 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Shared with you
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {sharedCollections.map((collection) => (
              <Collection
                key={collection.id}
                collection={collection} />
            ))}
          </Box>
        </Box>
      }
      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Your Collections
        </Typography>
        {loading ?
          <Collection.Skeleton />
          :
          (true ?
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {data.collections.filter(({ isSharedCollection }) => !isSharedCollection).map((collection) => (
                <Collection
                  key={collection.id}
                  collection={collection} />
              ))}
            </Box>
            :
            <Paper elevation={3} sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              You don't have any collections yet! Create your first Collection!
            </Paper>)
        }
      </Box>

    </Box>
  );
};

export default Collections;
