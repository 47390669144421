import { LAYER_FIELDS } from '@queries/layers';
import { gql } from '@apollo/client';

const CREATE_LAYER = gql`
  mutation CreateLayer($subcollectionId: ID!) {
    createLayer(subcollectionId: $subcollectionId) {
      ...LayerFields
    }
  }
  ${LAYER_FIELDS}
`;

const UPDATE_LAYER_NAME = gql`
  mutation UpdateLayer(
    $layerId: ID!, 
    $name: String!, 
  ) {
    updateLayer(id: $layerId, name: $name) {
      ...LayerFields
    }
  }
  ${LAYER_FIELDS}
`;

const UPDATE_LAYER_STATE = gql`
  mutation UpdateLayer(
    $layerId: ID!, 
    $state: LayerStateInput!
  ) {
    updateLayer(id: $layerId, state: $state) {
      ...LayerFields
    }
  }
  ${LAYER_FIELDS}
`;


const UPDATE_LAYER_POSITION = gql`
  mutation UpdateLayerPosition($layerId: ID!, $newPosition: Int!) {
    updateLayerPosition(id: $layerId, newPosition: $newPosition) {
      ...LayerFields
    }
  }
  ${LAYER_FIELDS}
`;

const DELETE_LAYER = gql`
  mutation DeleteLayer($layerId: ID!) {
    deleteLayer(id: $layerId) {
      id
    }
  }
`;

export {
  CREATE_LAYER,
  UPDATE_LAYER_NAME,
  UPDATE_LAYER_STATE,
  UPDATE_LAYER_POSITION,
  DELETE_LAYER
};
