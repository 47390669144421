import { COLLECTION_FIELDS } from '@queries/collections';
import { GENERATED_COLLECTION_FIELDS } from '@queries/generated-collections';
import { gql } from '@apollo/client';

const CREATE_COLLECTION = gql`
  mutation CreateCollection {
    createCollection {
      ...CollectionFields
    }
  }
  ${COLLECTION_FIELDS}
`;

const GENERATE_COLLECTION = gql`
  mutation GenerateCollection(
    $collectionId: ID!
  ) {
    generateCollection(id: $collectionId) {
      ...GeneratedCollectionFields
    }
  }
  ${GENERATED_COLLECTION_FIELDS}
`;

const UPDATE_COLLECTION_NAME = gql`
  mutation UpdateCollection(
    $collectionId: ID!, 
    $name: String!
  ) {
    updateCollection(id: $collectionId, name: $name) {
      ...CollectionFields
    }
  }
  ${COLLECTION_FIELDS}
`;

const UPDATE_COLLECTION_STATE = gql`
  mutation UpdateCollection(
    $collectionId: ID!, 
    $state: CollectionStateInput!
  ) {
    updateCollection(id: $collectionId, state: $state) {
      ...CollectionFields
    }
  }
  ${COLLECTION_FIELDS}
`;

const DELETE_COLLECTION = gql`
  mutation DeleteCollection(
    $collectionId: ID!
  ) {
    deleteCollection(id: $collectionId) {
      id
    }
  }
`;

export {
  CREATE_COLLECTION,
  GENERATE_COLLECTION,
  UPDATE_COLLECTION_NAME,
  UPDATE_COLLECTION_STATE,
  DELETE_COLLECTION
};
