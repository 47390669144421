import { CREATE_VARIATION_FIELDS, VARIATION_FIELDS } from '@queries/variations';

import { gql } from '@apollo/client';

const CREATE_VARIATION = gql`
  mutation CreateVariation(
    $layerId: ID!
    $fileName: String!
    $fileSize: Int!
    $fileType: MimeType!
    $fileLastModified: DateTime!
  ) {
    createVariation(
      layerId: $layerId
      file: {
        name: $fileName
        size: $fileSize
        mimeType: $fileType
        lastModified: $fileLastModified
      }
    ) {
      ...CreateVariationFields
    }
  }
  ${CREATE_VARIATION_FIELDS}
`;

const CREATE_VARIATION_COMPONENT = gql`
  mutation CreateVariationComponent(
    $variationId: ID!
  ) {
    createVariationComponent(variationId: $variationId) {
      ...VariationFields
    }
  }
  ${VARIATION_FIELDS}
`;

const UPDATE_VARIATION_NAME = gql`
  mutation UpdateVariation(
    $variationId: ID!, 
    $name: String!
  ) {
    updateVariation(id: $variationId, name: $name) {
      ...VariationFields
    }
  }
  ${VARIATION_FIELDS}
`;

const UPDATE_VARIATION_STATE = gql`
  mutation UpdateVariation(
    $variationId: ID!, 
    $state: VariationStateInput!
  ) {
    updateVariation(id: $variationId, state: $state) {
      ...VariationFields
    }
  }
  ${VARIATION_FIELDS}
`;

const UPDATE_VARIATION_FILE = gql`
  mutation UpdateVariation(
    $variationId: ID!, 
    $fileName: String!
    $fileSize: Int!
    $fileType: MimeType!
    $fileLastModified: DateTime!
  ) {
    updateVariation(
      id: $variationId,       
      file: {
        name: $fileName
        size: $fileSize
        mimeType: $fileType
        lastModified: $fileLastModified
      }
    ) {
      ...VariationFields
    }
  }
  ${VARIATION_FIELDS}
`;


const DELETE_VARIATION = gql`
  mutation DeleteVariation($variationId: ID!) {
    deleteVariation(id: $variationId) {
      id
    }
  }
`;

export { CREATE_VARIATION, CREATE_VARIATION_COMPONENT, UPDATE_VARIATION_NAME, UPDATE_VARIATION_STATE, UPDATE_VARIATION_FILE, DELETE_VARIATION };
