import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import SquareIconButton from '@components/SquareIconButton';

const CloseButton = ({ onClick, sx = {}, tooltipTitle }) => <SquareIconButton
    onClick={onClick}
    sx={{ p: 0.5, m: 1, mr: 1.5, ...sx }}
    tooltipTitle={tooltipTitle}
>
    <CloseIcon sx={{ fontSize: '1.2rem', p: 0 }} />
</SquareIconButton>

export default CloseButton;